.coach__top {
    margin-bottom: 20px;
    text-align: center;
}

.coach__top h2 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
}

.coach__top p {
    color: var(--heading-color);
    font-size: 1rem;
    line-height: 28px;
}

.coach__wrapper {
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2.5% 2.5%;
    border-radius: 10px;
}

.coach__item {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    text-align: center; /* Center the content */
}

.coach__icon {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coach__icon img {
    width: 100%;
    height: auto;
    max-width: 300px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 25px;
}

.coach__content {
    width: 100%;
}

.coach__content h3 {
    font-size: 1.3rem;
    color: var(--primary-color);
    font-weight: 700;
    margin-bottom: 0.6rem;
}

.coach__content p {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary-color);
}

@media screen and (max-width: 970px) {
    .coach__item {
        width: 100%;
    }
}
