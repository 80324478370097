.hero__wrapper{
    display: flex;
   
    /* justify-content: space-between; */
    padding-top: 50px;   
    padding: auto; 
    margin-top: 10px;
    margin-bottom: 10px;
}
.highlights{
    color: var(--primary-color);
}
.hero__content {
    padding-right: 10%;
}
.hero__img{
    padding-left: 10%;
}
.hero__content .section__title{
    font-size: 3rem;
    padding-right: 50px;
    line-height:60px;
    margin-bottom: 20px;
}


.hero__content p{
    font-size: 1.1rem;
    color: var(--heading-color);
    margin-bottom: 30px;
    margin-left: 30px;
    line-height: 30px;    
}

.hero__btns{
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
}



/* .watch__btn span{
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    color: var(--primary-color);
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 6px -1px ;
} */
.watch__btn:hover{
    color: var(--primary-color);
}

.box-01{
    width: 460px;
    height: 460px;
    border-radius: 100%;
    border: 25px solid #c2357b;        
}
.box-02{
    width: 410px;
    height: 410px;
    border-radius: 100%;
    border: 25px solid #f62a59;        
}
.box-03{
    width: 360px;
    height: 360px;
    border-radius: 100%;
    border: 25px solid #ff6129;        
}

.hero__img-wrapper{
    position: relative;
    /* top: 10%;
    left: 7.5%;  */
    z-index: 1;
}
.box__img{
    position: absolute;
    display: flex;
    align-items: center;
    width: 65%;
    top: -23%;
    left: 7.5%; 
    z-index: 10;   
}
.box__img img{
    width: 130%;
}

.imageWH {
    width: 100%;
    height: 100%;
    padding: 10px;
}
.sty{
    border-radius: 5px; 
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    position: absolute;
}

.muscle{
    top: 50%;
    right: -11%; 
    background:#00fb19;
    color:  #fff;
}

.wheight{
    top: 50%;
    left: -11%;     
    background:#ffef76;
    color:  #fff;
}
.heart{
    top: 2%;
    right: -1%;
    background:orchid;
    color:  #fff;
 
}

.dumble__icon{
    top: 2%;
    left: -5%;
    background:var(--primary-color);
    color:  #fff;    
}

@media screen and (max-width: 970px){
     .hero__wrapper{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
   .hero__content{
        /* align-items: center; */
    margin-bottom: 10%;  
    }
     .hero__wrapper .hero__img{
    padding-top: 10%;
    margin-bottom: 10%;
    }
}