.contact form div {
    display: flex;
    
  }
  /* .contact {
    background:;
  } */
  .container form{
    max-width: 80%;
    margin: auto;
    background: #88048b;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 20px;
  } 
  .container h4{
    color: #fff;
  }
  .contact textarea {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #512174;
    margin-bottom: 20px;
  }

  button {
    border-radius: 5px;
    padding: 15px 15px;
    background:var(--bg-gradient);
    border: none;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
  
  .contact input {
    margin-bottom: 20px;
    margin-right: 5px;
   
    /* width: %; */
    padding: 10px;
    border: 1px solid #512174;
    border-radius: 5px;
  
  
    
  }
  
  .contact form {
    padding: 30px;
  }
  @media screen and (max-width: 970px) {
    .contact h4 {
      width: 100%;
      margin-bottom: 30px;
    }
    .contact form div {
      flex-direction: column;
    }
    .contact input {
      width: 100%;
    }
  }