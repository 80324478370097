/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

:root {
  --primary-color: #74e0f0;
  --heading-color: #f5eadf;
  
  --bg-gradient: linear-gradient(
    90deg,
    #f62a59 15%, #ff6129 85%
  );
  --font-name: "Questrial", sans-serif;

 
}

/* ======== reset default browser styles ======= */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);

  background-color: #170721;
}
html {
  scroll-behavior: smooth;
}

section {
  padding: 20px 0px;
}

a {
  text-decoration: none;
  color: unset;
} 

a:hover {
  color: unset;
}

ul {
  list-style: none;
}

/* ============ reusable class =========*/
.container {
  /* width: 1140px;  */
  width: 90%;
  /* container to be modified to a percentage like 80% or somethign like that */
  padding: 0px 15px;
  margin: auto;
}

.section__title {
  color: var(--heading-color);
  font-weight: 800;
}

