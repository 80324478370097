.cont_extr{
    margin-top: 20px;
    
}
.about_wrapper{
    background: #88048b;
    border-radius:5rem;    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 70px 50px;
}
.about_item{
    align-items: center;
    text-align: center;
}
.about_item h1 { 
    margin-bottom: 10px;
    color: var(--heading-color);
}
.about_item p { 

    color: var(--heading-color);
    align-items: center;
    column-gap: 1.7rem;
    font: var(  --font-name);
    font-weight: 400 ;
    font-size: 1.2rem;
    line-height: 30px;
}