.start__wraper {
    display: flex;
    justify-content: space-between;
    column-gap: 3rem;
    align-items: center;
}

.start__img  {
    width: 100%;
    left : 230%;
    }

.start__img img {
    width: 100%;

}

.start__content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.start__content p {
    color: var(--heading-color);
    font-size: 1.1rem;
    line-height: 28px;
    margin-bottom: 10px;
}

@media screen and (max-width: 970px) {
    .start__wraper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 10%;
    }
    .start__img{
        margin-bottom: 10px;
    }

    .start__content {
        margin-top: 10%;
    }
}