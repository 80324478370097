header {
  width: 100%;
  /* height: 70px; */
  line-height: 70px;
}
.cont{
  max-width: 100%;
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .nav__wrapper {
} */
  /* .cont .flex{
    padding-top: 1rem;
  } */
  /* .navc{
    padding-left: 1rem;
  } */
  header img {
    width: 10%;
  }
  .register__btn {
    border: none;
    outline: none;
    padding: 10px 20px;
    /* color: black; */
    background: var(--bg-gradient);
    border-radius: 5px;
    font-family: var(--font-name);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    z-index: 3;
}
.logo {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.logo__img {
  background: var(--bg-gradient);
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__img img {
  width: 100%;
}

.logo h2 {
  color: var(--heading-color);
  font-weight: 700 !important;
  font-size: 1.8rem;
}
.nav {
  color: var(--heading-color);
  font-weight: 600 !important;
 
  cursor: pointer;
  transition: 0.2s;
  font-size: 1.1rem;
}
.nav .flex {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  /* flex-direction: row; */
  /* flex-wrap: wrap; Add this line */
}

.nav Link:hover {
  color: var(--primary-color);
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
  .links{
    color: var(--heading-color);
    font-weight: 600 !important;   
    cursor: pointer;
  }
  .links ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .links ul li{
    padding: 10px;

  }
  /* header a {
    margin-left: 30px;
    transition: 0.5s;
    font-weight: 500;
  } */
  /* header a:hover {
    color: #27ae60;
  } */
  header span {
    width: auto;
    padding: 0px 6px;
    /* background: #27ae60; */
    border-radius: 50px;
    color: #ffffff;
    margin-right: 4px;
    font-weight: 400;
  }
  header h4 {
    font-weight: 500;
    margin-right: 20px;
  }
  header .toggle {
    display: none;
  }
  @media screen and (max-width: 970px) {
    /* header {
      padding: 0;
    } */
  
    header .nav__right {
      /* display: block; */
      margin-top: 10px;
    }
    header .links  .flex {
      display: none;
    }
    header .toggle {  
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
   
    header .links .small {
      position: absolute;
      top: 10vh;
      left: auto;
      right: 0; /* Set right to 0 to align the content to the right */
      width: 25%;
      border-radius: 20px;
      border : 2px red solid;
      /* padding: ; */
      margin-right: 5%;
    }
    header .links .small{
      display: flex;
      align-items: center;
      justify-content: center;
      /* Add this line */
      flex-direction: column;
      background: #ff6129;
    }
   
  }