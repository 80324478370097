.exercise__top{
    margin-bottom: 20px;
    text-align: center;
}
.exercise__top h2{
    /* color: black; */
    font-size: 2.4rem;
    margin-bottom: 1rem;
}
.exercise__top p{
    color: var(--heading-color);
    /* color: black; */
    font-size: 1rem;
    line-height: 28px;
}
.exercise__wrapper{
    background:var(--bg-gradient);
    border-radius:0.5rem;    
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5% 2.5%;
    border-radius: 10px;
}
.exercise__item{
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    width: 33%;
}
.exercise__icon{
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 5px;
  
}
.exercise__icon img{
    width: 100%;
}
.exercise__content{
    width: 80%;
}
.exercise__content h3{
    font-size: 1.3rem;
    color:black;
    font-weight: 700;
    margin-bottom: 0.6rem;
}

.exercise__content p{
    font-size: 1rem;
    font-weight: 600;
    color : black;
}
@media screen and (max-width: 970px){
    .exercise__wrapper{
   flex-direction: column;
   align-items: center;
   justify-content: center;
   }

   .exercise__item{
    width: 100%;
    padding: 2.5%;
    /* border: 10px solid aqua; */
   }
   .exercise__content{
    width: 100%;
    /* border: 10px solid aqua; */
   }
}