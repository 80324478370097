.pricing__top {
  text-align: center;
}

.pricing__top h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.pricing__top p {
  font-size: 1.1rem;
  color: #f5eadf;
  line-height: 20px;
  margin-bottom: 50px;
}

/*  wrapper  */


.pricing__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing__item {
  /* box-shadow: rgba(255,255,0, ) 0px 5px 15px 0px; */
  border-radius: 10px;
  width: 31%;
  background: #88048b;
  /* background: var( --bg-gradient); */
}

.pricing__card-top {
  padding: 25px;
  /* border-bottom: 1px solid red; */
  text-align: center;
}

.pricing__card-top h2 {
  font-size: 1.5rem;
  margin-bottom: .9rem;

}

.pricing__card-top .pricing {
  font-size: 1.4rem;
}

.pricing__card-top .pricing span {
  font-size: 1rem;
  color: #f5eadf;
}

.pricing__item .services {
  padding: 20px;
}

.price_item {
  display: flex;
  align-items: center;
  column-gap: 1.7rem;
  font: var(--font-name);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
}

.pricing__item .services ul li span .ico {
  font-size: 0.7rem;
}


.services {
  text-align: center;
}

.services button {
  margin-top: 50px;
}

.pricing__item-02 .pricing__card-top {
  /* background: var(--primary-color); */
  background: #c2357b;

  border-radius: 10px 10px 0px 0px;
}

.pricing__item-02 .pricing__card-top h2 {
  color: #f5eadf;
  font-weight: 500;
}

.pricing__item-02 .pricing__card-top h2 span {
  color: rgba(255, 255, 255, 0.788);
}

.pricing__item .services ul li{
  color: #f5eadf;
}


@media screen and (max-width: 970px) {
  .pricing__wrapper {

    flex-direction: column;
    margin-bottom: 10px;

  }

  .container .price_ele {

    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .container .price_ele .pricing__wrapper .pricing__item {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;

  }

  .container .price_ele .pricing__top {
    /* width: 80%; */
    align-items: center;
    justify-content: center;
    text-align: center;
    /* padding: 10px; */

    border-radius: 10px;
    /* margin-top: 0px; */
  }
}