
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
/* *{
    box-sizing: border-box;
}
html{
     height: 100%;
} */
.signup{
    height: 100%;
    display: grid;
    place-items: center;
    margin: 0;
    padding: 0 32px;
    /* background: #f5f5f5; */
    font-family: "Ubuntu";
    animation: rotate 6s infinite linear ;
    margin-top: 10px;
    margin-bottom: 10px;
}
/* .circle{
    position: fixed;
    top: -50vmin;
    left: -50vmin;
    width: 100vmin;
    height: 100vmin;
    border-radius: 47% 53% 61% 39% / 45% 51% 49% 55%;
    background: #65c8ff;


} */


/* .circle::after{
    content: "";
    position: inherit;
     right: -50vmin;
     bottom: -55vmin;
     width: inherit;
     height: inherit;
     border-radius: inherit;
     background: #143d81;

} */
.card{
    overflow: hidden;
    position: relative;
    z-index: 3;
    width: 100%;
    margin: 0 20px;
    padding: 160px 30px 38px;
    border-radius: 1.25rem;
    background: #44045c;
    /* #531c4f; */
    text-align: center;
    box-shadow: 0 100px 100px rgb(0 0 0 / 10%);
}


.card::before{
    content: "";
    position: absolute;
    background: #216ce7;
    top: -880px;
    left: 50%;
    width: 1000px;
    height: 1000px;
    border-radius: 50%;
    translate: -50% 0;

}
.card .logo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% ;
    color: var(--bg-gradient);
    height: 70px;
    width: 70px;
    top: 30px;
    left: 50%;
    translate: -50% 0;
}

.card .logo img{
    /* font-size: 50px ;

    color: #216ce7;
    font-weight: 500; */
    width: 48px;
    height: 48px;
}
.card>h2{
    font-size:22px ;
    font-weight: 400;
    margin: 0 0 30px;
    color: var(--heading-color);
}


.form{
    margin: 0 0 30px;
    display: grid;
    gap: 18px;
}


.form>input , .form>button{
    width: 100%;
    height: 50px;
    border-radius: 20px;
    }
    .form>input{

        border: 2px solid #c1d5e5 ;
        font-family: inherit;
        font-size: 16px;
        padding: 0 24px;
        color: #170721;
        transition: all 0.375s;
    }
    .form>input:hover{
        border: 2px solid #33007a;
    }


    .form>input::placeholder{
        color: grey;
    }

    .form>button{

        cursor: pointer;
        width: 100%;
        height: 50px;
        padding: 0 16px;
        background: #216ce7;
        color:#f9f9f9 ;
        border: 0;
        font-family: inherit;
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        letter-spacing: 2px;
        transition: all 0.375s;
        }

.form>button:hover{
    color: #fff;
    background: #143d81;

}
.card>footer{
    color: grey;
    transition: all 0.375s;

}

.card>footer>a:hover{
    color:#143d81;

}


@media(width>=500px){
    body{
        padding:0;
    }
    .card{
        margin: 0;
        width: 500px;
    }
}