.footer {
    padding: 30px 0px;
    /* background: #333333; */
    background: #170721;
  }
  
  .footer__wrapper {
    display: flex;
  
    justify-content: space-between;
    column-gap: 2rem;
  }
  
  .footer__box {
    width: 50%;
  }
  
  /* .footer__box p {
    color: var(--heading-color);
    line-height: 28px;
    margin-top: px;
  }
   */
  .footer__title {
    font-size: 1.2rem;
    color: var(--heading-color);
    margin-bottom: 10px;
  }
  
  .footer__links li {
    line-height: 25px;
  }
  
  .footer__links li a {
    color: var(--heading-color);
    cursor: pointer;
  }
  
  .footer__links li a:hover {
    color: var(--primary-color);
  }
  
  .copyright {
    margin-top: 10px;
    text-align: center;
    color: white;
    font-size: 0.9rem;
  }